import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: '1',
        title: 'Direct Hire Placements',
        description: 'When you are looking for a long-term permanent placement with high-skilled candidates, we are here to help you. We provide qualified individuals who have been trained to be productive for your team. Discover best candidate for better company growth.'
    },
    {
        icon: '2',
        title: 'Contracted Personnel',
        description: 'Recruiting an in-house staff could be a costly affair with all the resources offered like training, insurance, and many more. Contracting out work has many benefits for both parties involved. It saves time and money by reducing overhead costs, while providing an opportunity for new ideas and expertise.'
    },
    {
        icon: '3',
        title: 'Project Staffing',
        description: 'The project staff might not be able to meet all the requirements of the project on time. Sometimes the team must plan out to ensure that they have enough people available to complete the work required for project. Hire an expert who will make your business better by adding value and solving problems that are unique for organization.'
    },
    {
        icon: '4',
        title: 'Contingent Staffing',
        description: 'We help our clients by offering them customized solutions which are tailored to meet their temporary/contingent requirements. Our consultants work closely with the client to provide scalable staffing solutions with flexible contracts. We focus on helping companies grow through effective staffing.'
    },
]

const ServiceFour = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                <div className="line"></div>
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title"><a dangerouslySetInnerHTML={{__html: val.title}}></a></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceFour;