import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
    {
        form: "San Diego",
        description: "They satisfied me greatly. The time it takes to go from applying to beginning your first job assignment is really short, and they assisted me in receiving an offer with sponsorship in just 15 days. They are very good at what they do.",
        name: "Vasim Khan",
        subtitle: "Frontend Developer",
        image: "testimonial.webp"
    },
    {
        form: "New York",
        description: "Apexarrow Consultancy is one of the best recruiting agencies I worked with. The recruiter knew how to dive deep into my skill & experience & matched me with a perfect job opportunity. They are very prompt in getting in touch & take every possible initiative to get their jobseekers their dream job.",
        name: "Claire Bolton",
        subtitle: "Java Backend Developer",
        image: "testimonial.webp"
    },
    {
        form: "Los Angeles",
        description: "Apexarrow Consultancy is very professional, they called me for a position, and explained the position honestly. I had my interview, they liked me....I got hired!",
        name: "Sinny Christian",
        subtitle: "Data Analyst",
        image: "testimonial.webp"
    },
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-12 col-md-9">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="b1">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/apex/testimonial/${data.image}`} alt="Apexarrow testimonial" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















