import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterThree from '../common/footer/FooterThree';
import BrandThree from '../elements/brand/BrandThree';
import AboutTwo from '../elements/about/AboutTwo';
import AboutFour from '../elements/about/AboutFour';
import ServiceSix from "../elements/service/ServiceSix";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import CircleProgress from "../elements/progressbar/CircleProgress";


const BannerData = [
    {
        image: "/images/bg/bg-image-20.jpg",
        title: "About Us",
        description: "Apexarrow Consultancy have helped thousands of students achieve their dream careers. Our team can assist you with everything from finding the right job to landing one of the most competitive offers in both full-time and contractual-based hiring solutions."
    },
]


const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-8 height-750">
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="inner text-center">
                                            <span className="subtitle theme-gradient">About Apexarrow Consultancy</span>
                                            <h1 className="title" dangerouslySetInnerHTML={{ __html: data.title }}></h1>
                                            <p className="description" dangerouslySetInnerHTML={{ __html: data.description }}></p>
                                            <div className="button-group mt--30">
                                                <a className="btn-default btn-large" href="/services">Explore Services</a>
                                                <a className="btn-default btn-border btn-large" href="/contact">Get in Touch</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* End Slider Area  */}

                <Separator />

                <AboutTwo />

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Apexarrow Consultancy"
                                    title="Mission and Vision"
                                />
                            </div>
                        </div>
                        <ServiceSix
                            serviceStyle="service__style--1 bg-color-blackest radius mt--25"
                            textAlign="text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}
                <Separator />

                <div className="rwt-elements-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--25">
                            <div className="col-lg-10 offset-lg-1">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Core Values"
                                    title="Your Business, Our Solution"
                                    description="Apexarrow Consultancy is one of the most trusted partners, more than 107+ candidates places."
                                />
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1 mt--10">
                            <CircleProgress />
                        </div>
                    </div>
                </div>

                <FooterThree />
            </main>
        </>
    )
}

export default AboutUs;
