import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree';


import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import ServiceFour from '../elements/service/ServiceFour';
import AdvanceTabOne from '../elements/advancetab/AdvanceTabOne';
import AdvancePricingOne from '../elements/advancePricing/AdvancePricingOne';
import BrandTwo from '../elements/brand/BrandTwo';

import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ProgressbarTwo from "../elements/progressbar/ProgressbarTwo";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import ServiceOne from "../elements/service/ServiceOne";
import AboutFour from '../elements/about/AboutFour';


const BannerData = [
    {
        image: "/images/bg/bg-image-20.jpg",
        title: "Apexarrow<br />Consultancy",
        description: "Apexarrow globally known for recruiting and hiring talents for <br/>companies and getting candidates their dream jobs."
    },
]


const Apexarrow = () => {
    return (
        <>
            <SEO title="Staffing Solutions for all Enterprise" />
            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-8 height-650">
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="inner text-center">
                                            <span className="subtitle theme-gradient">IT Solutions for all Enterprise</span>
                                            <h1 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h1>
                                            <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                            <div className="button-group mt--30">
                                                <a className="btn-default btn-large" href="/services">Explore Services</a>
                                                <a className="btn-default btn-border btn-large" href="/contact">Get in Touch</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Staffing Services We Offer Businesses"
                                    description = "We are one of the leading recruitment agencies in USA offering an array of services including Staffing Solutions, Recruitment Services, HR Consulting, Training and Development Services, etc. We provide high quality potential candidates to clients across industries.Our team of experts has years of experience in recruiting professionals and meeting the business needs from various fields like Engineering, IT & Non-IT. Our services are crafted to suit your specific demands, offering you the best staffing services."
                                    />
                            </div>
                        </div>
                        <ServiceFour 
                            serviceStyle = "service__style--1 icon-circle-style with-working-process"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator />

                  {/* Start Elements Area  */}
                  <div className="rwt-about-area rn-section-gapTop">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <SectionTitle
                                    textAlign = "text-start"
                                    radiusRounded = ""
                                    subtitle = "Our Expertise"
                                    title = "Kick-Start Your Career with Apexarrow Consultancy"
                                    description = "Whether you're searching for career opportunities or looking to hire talents, we, at Apexarrow Consultancy will take a personalized <br/>approach to help you attain your desired goals."
                                />
                            </div>
                            <div className="col-lg-6">
                                <div className="rbt-progress-style-1">
                                    <ProgressbarTwo />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What We Do"
                                    title = "Apexarrow Consultancy Expertise"
                                    description = "We are the best in staffing and recruitment, and help businesses hire the best of talents. Whether you are looking for Software Developers, Designers, Business Analyst, Cloud Engineer or Data Analyst, we recruit all IT and Non-IT  Professionals. We recruit skilled and talented candidates with our expertise and experience."
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />


                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Why Choose us"
                                    title = "Reason for Choosing Apexarrow Consultancy"
                                    description = "We provide true, effective solutions for your career in the global industry."
                                    />
                            </div>
                        </div>
                        <AboutFour />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-elements-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--20">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Client Feedback"
                                        title = "Testimonial"
                                    />
                                </div>
                            </div>
                            <TestimonialThree teamStyle="" />
                        </div>
                    </div>
                    {/* End Elements Area  */}

                <FooterThree />
            </main>
        </>
    )
}

export default Apexarrow;
