import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>


            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                        <div className="col-lg-12">
                            <div className="rn-contact-address mt_dec--30">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="rn-address">
                                            <div className="icon">
                                                <FiMail />
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">Our Email Address</h4>
                                                <p><a href="mailto:info@apexarrowconsultancy.com">info@apexarrowconsultancy.com</a></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="rn-address">
                                            <div className="icon">
                                                <FiMapPin />
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">Our Location</h4>
                                                <p>302, Himalaya Emarald, Nr I.O.C Petrol Pump, Shivranjani Over Bridge, Ahmedabad</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>
            </div>
        </>
    )
}
export default ContactOne;