import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterThree from '../common/footer/FooterThree';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import ServiceFour from '../elements/service/ServiceFour';
import AboutFour from '../elements/about/AboutFour';
import SlipThree from '../elements/split/SlipThree';
import BrandThree from '../elements/brand/BrandThree';
import ScrollAnimation from "react-animate-on-scroll";
import ServicesSeven from '../elements/service/ServicesSeven';

import { Link } from "react-router-dom";


const BannerData = [
    {
        image: "/images/bg/bg-image-20.jpg",
        title: "Services",
        description: "Our team of experts has years of experience in recruiting professionals and meeting the business needs from various fields like Engineering, IT & Non-IT. Our Services are formulated to suit your specific demands, offering you the best staffing services."
    },
]



const Services = () => {
    return (
        <>
            <SEO title="Services" />

            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-8 height-750">
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="inner text-center">
                                            <span className="subtitle theme-gradient">Apexarrow Consultancy Services</span>
                                            <h1 className="title" dangerouslySetInnerHTML={{ __html: data.title }}></h1>
                                            <p className="description" dangerouslySetInnerHTML={{ __html: data.description }}></p>
                                            <div className="button-group mt--30">
                                                <a className="btn-default btn-border btn-large" href="/contact">Get in Touch</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Services"
                                    title="Most Trusted Staffing and Recruitment Agency"
                                    description="The world, like the demands and challenges it faces, is always evolving for staffing and job recruitment. We have articulated our services to suit all your individual and company objectives as a one-stop solution for all your staffing and recruitment requirements.  Whether you are looking to hire a talented employee or looking for your dream job, we offer services that suits your needs."
                                />
                            </div>
                        </div>
                        <ServiceOne
                            serviceStyle="service__style--1 bg-color-blackest radius mt--25"
                            textAlign="text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="What we can do for you"
                                    title="Staffing Services We Offer Businesses To Hire"
                                    description="We are one of the leading recruitment agencies in the US offering an array of services including Staffing Solutions, Recruitment Services, HR Consulting, Training and Development Services, etc. We provide high quality potential candidates to clients across industries."
                                />
                            </div>
                        </div>
                        <ServiceFour
                            serviceStyle="service__style--1 icon-circle-style with-working-process"
                            textAlign="text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Work Process"
                                    title="How do we help Job Seekers get their Dream Job?"
                                    description="With years of expertise in staffing and recruitment, we have built a strategic approach to help businesses and talents to get the best HR solutions for all your requirements. Here’s how we help job seekers choose their dream job."
                                />
                            </div>
                        </div>
                        <div className='row'>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                        <div className="service service__style--1 bg-color-blackest radius mt--25 text-center">
                                            {/* <div className="icon">
                                                {val.icon}
                                            </div> */}
                                            <div className="content">
                                                <h4 className="title w-600"><a>Counselling</a></h4>
                                                <p className="description b1 color-gray mb--0">The basic enrollment process starts with counselling and candidate profile screening.</p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                        <div className="service service__style--1 bg-color-blackest radius mt--25 text-center">
                                            {/* <div className="icon">
                                                {val.icon}
                                            </div> */}
                                            <div className="content">
                                                <h4 className="title w-600"><a>Resume Building</a></h4>
                                                <p className="description b1 color-gray mb--0">As per the counselling, the resume building process begins with the required skills.</p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                        <div className="service service__style--1 bg-color-blackest radius mt--25 text-center">
                                            {/* <div className="icon">
                                                {val.icon}
                                            </div> */}
                                            <div className="content">
                                                <h4 className="title w-600"><a>Interview Guidance</a></h4>
                                                <p className="description b1 color-gray mb--0">With our dedicated recruiters and experts, we offer interview guidance, mock interviews and preparation.</p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                        <div className="service service__style--1 bg-color-blackest radius mt--25 text-center">
                                            {/* <div className="icon">
                                                {val.icon}
                                            </div> */}
                                            <div className="content">
                                                <h4 className="title w-600"><a>Offer Letter</a></h4>
                                                <p className="description b1 color-gray mb--0">Get your dream job, career guidance and offer letter with the network and desired skills.</p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap" id="services-expertise">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Expertise"
                                    title="Top Expertise We Help Businesses Hire"
                                    description="Our team of experts has years of experience in recruiting professionals and meeting the business needs from various fields like Engineering, IT & Non-IT. Our Services are formulated to suit your specific demands, offering you the best staffing services."
                                />
                            </div>
                        </div>
                            <ServicesSeven/>
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator />


                <FooterThree/>
            </main>

        </>
    )
}

export default Services;
