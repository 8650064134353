import React from 'react';
import { FiGlobe, FiUsers  } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'IT Staffing and Recruitment',
        description: "Looking for IT Staffing and Recruitment, then you should consider outsourcing this task to a company who can provide you with qualified candidates. This way, you won’t have to spend time searching through resumes and interviewing potential employees yourself. Instead, you’ll be able to focus on other aspects of running your business."
    },
    {
        icon: <FiGlobe />,
        title: 'Non-IT Staffing and Recruitment',
        description: "If you are looking for Non IT recruitment services, then you are at the right place. Staffing professors offers the leading recruitment services in the following field. Our clients include small businesses, large corporations and other agencies. Contact us now to find out how we can assist you!"
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="/services" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0 text-justify" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;