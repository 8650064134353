import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="/images/apex/thumbnail/apexarrow-about-us.jpeg" alt="Apexarrow about us" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">About our company</span></h4>
                                    <h2 className="title mt--10"><strong className='color-primary'>Apexarrow Consultancy</strong> dedicated to empower people</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Apexarrow Consultancy was built on the disruptive idea that everyone has access to an affordable HR professional who understands how businesses work. They help talents to get the best opportunity with the business to grow like a Fortune 500 company. We've taken our experience of managing large teams across industries and applied it to staffing companies. Our goal has always been to make sure that each client gets the most out of their team members by making sure that employees feel appreciated, understood, valued and rewarded.</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> We've helped hundreds of companies become more efficient and effective by taking away all the mystery and complexity of running a business and transforming it into a productive recruitment service.</li>
                                        <li><span className="icon"><FiCheck /></span> We continually offer effective staffing solutions and comprehend the objectives and requirements of our clients. We help companies achieve operational excellence by providing them with the best talent available.</li>
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-border custom-button-primary" to="/contact">Contact Us<i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
