import React from 'react';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";

const ServiceList = [
    {
        title: 'Frontend Developer',
    },
    {
        title: 'Backend Developer',
    },
    {
        title: 'Full-stack Developer',
    },
    {
        title: 'DevOps Developer',
    },
    {
        title: 'UI - UX Designers',
    },
    {
        title: 'Cloud Engineers',
    },
    {
        title: 'Scrum Master',
    },
    {
        title: 'IT project manager',
    },
    {
        title: 'Quality Assurance',
    },
    {
        title: 'Data Analyst',
    },
    {
        title: 'Data Scientist',
    },
    {
        title: 'Business Analyst',
    },
    {
        title: 'Supply Chain Analyst',
    },
    {
        title: 'Cybersecurity Analyst',
    },
    {
        title: 'Database Administrator',
    },
    {
        title: 'Data Engineer',
    },
]



const NonITServiceList = [
    {
        title: 'Project Manager',
    },
    {
        title: 'Financial Analyst',
    },
    {
        title: 'Business Analyst',
    },
    {
        title: 'Product Designer',
    },
]

const ServicesSeven = () => {
    return (
        <div className="row row--15 service-wrapper">
            <div className="col-lg-12">
                <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                    <h2 className="title w-600 mb--20 text-center">IT Staffing and Recruitment</h2>
                </ScrollAnimation>
            </div>
            {ServiceList.map((val, i) => (
                <div className="col-lg-3 col-md-3 col-sm-3 col-12" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className="service service__style--1 bg-color-blackest radius mt--25 text-center">
                            <div className="content">
                                <h4 className="title w-600"><a dangerouslySetInnerHTML={{ __html: val.title }}></a></h4>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}

            <div className="col-lg-12">
                <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                    <h2 className="title w-600 mt--40 mb--20 text-center">Non - IT Staffing and Recruitment</h2>
                </ScrollAnimation>
            </div>
            {NonITServiceList.map((val, i) => (
                <div className="col-lg-3 col-md-3 col-sm-3 col-12" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className="service service__style--1 bg-color-blackest radius mt--25 text-center">
                            <div className="content">
                                <h4 className="title w-600"><a dangerouslySetInnerHTML={{ __html: val.title }}></a></h4>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServicesSeven;