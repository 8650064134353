import React from 'react';
import { FiActivity, FiGlobe, FiUsers } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Reliable Staffing Solutions',
        description: "Our mission and vision is to offer you a reliable staffing solution that can help streamline processes, improve employee satisfaction, reduce costs, and increase productivity. It also helps companies manage their workforce better by providing them with accurate information about employees, including performance reviews, skills, training needs, and more."
    },
    {
        icon: <FiGlobe />,
        title: 'Training and Consultancy For Job Seekers',
        description: "We provide training and consultancy services for job seekers.  Our team has worked with many companies across different industries like IT and Non-IT and has an exceptional network. We offer a wide range of services including career counseling, resume writing, interview preparation, networking, and more. Our goal is to help people get their dream jobs!"
    },
]

const ServiceSix = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="/services" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0 text-justify" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceSix;