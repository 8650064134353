import React from 'react';
import Logo from "../../elements/logo/Logo";
import { Link } from "react-router-dom";

const CalltoActionEight = () => {
    return (
        <div className="rn-callto-action rn-call-to-action style-8 content-wrapper">
            <div className="container">
                <div className="row row--0 align-items-center footer">
                    <div className="col-lg-4 text-left">
                        <div className="logo">
                            <div>
                            <Link to={process.env.PUBLIC_URL + "/"}>
                                <img className="logo-light" src="/images/apex/logo/apex-footer-dark.png" alt="Apexarrow Logo" />
                                <img className="logo-dark" src="/images/apex/logo/apex-footer-white.png" alt="Apexarrow Logo" />
                            </Link>
                            </div>
                            <h5 className='b1 mt--10 title'>Apexarrow Consultancy globally known for recruiting and hiring talents for companies and getting candidates their dream jobs.</h5>
                        </div>
                    </div>
                    <div className='col-lg-6 text-left offset-lg-2'>
                        <h2 className='title'>Get in Touch</h2>
                        <h5 className="title">Email : <a href="mailto:info@apexarrowconsultancy.com">info@apexarrowconsultancy.com</a></h5>
                        <h5 className="title">Address : <a>302, Himalaya Emarald, Nr I.O.C Petrol Pump, Shivranjani Over Bridge, Ahmedabad</a></h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionEight;