import PropTypes from "prop-types";
import React from 'react'


const SEO = ({ title }) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>ApexArrow Consultancy</title>
            <meta name='robots' content='index, follow' />
            <meta name="title" content="ApexArrow Consultancy" />
            <meta name="description"
                content="We are one of the leading recruitment agencies in USA offering an array of services including Staffing Solutions, Recruitment Services, HR Consulting, Training and Development Services, etc. We provide high quality potential candidates to clients across industries.Our team of experts has years of experience in recruiting professionals and meeting the business needs from various fields like Engineering, IT & Non-IT. Our services are crafted to suit your specific demands, offering you the best staffing services." />
            <meta name="keywords" content="Staffing, Recruitment, Best Staffing Company in USA, IT jobs, IT jobs in USA" />
            <meta name='reply-to' content='info@apexarrowconsultancy.com' />
            <meta name='copyright' content='ApexArrow Consultancy' />
            <meta name="revisit-after" content="5" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


